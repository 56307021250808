.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
}

.SliderRoot[data-orientation="horizontal"] {
  height: 20px;
}

.SliderRoot[data-orientation="vertical"] {
  flex-direction: column;
  width: 20px;
  height: 100px;
}

.SliderTrack {
  background-color: var(--digitalent-green-light);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
}

.SliderTrack[data-orientation="horizontal"] {
  height: 0.4rem;
}

.SliderTrack[data-orientation="vertical"] {
  width: 3px;
}

.SliderRange {
  position: absolute;
  background-color: var(--digitalent-green);
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--digitalent-green);
  border-radius: 10px;
}

.SliderThumb:hover {
  background-color: var(--digitalent-green);
  outline: 2px solid var(--digitalent-green-light);
  box-shadow: 0 0 0 5px var(--digitalent-green);
}

.SliderThumb:focus {
  outline: 2px solid var(--digitalent-green-light);
  box-shadow: 0 0 0 5px var(--digitalent-green);
}
